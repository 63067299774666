import React from "react";
import { graphql } from "gatsby";
import Grid from "../components/grid";

const Print = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  console.log(edges);
  return <Grid edges={edges} title="Print" />;
};

export default Print;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { tags: { in: ["print"] } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            tile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
